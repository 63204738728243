import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import Presentation from "components/presentation"
import MessageMe from "components/message-me"

const ContactCard = () => {
  const data = useStaticQuery(graphql`
    query AvatarQuery {
      avatar: file(relativePath: { eq: "zwergius.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 200) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Presentation image={<Img fluid={data.avatar.childImageSharp.fluid} />}>
      <h2>
        <FormattedMessage id="home.title" />
      </h2>
      <h1>
        <FormattedMessage id="home.subtitle" />
      </h1>
      <p>
        <FormattedMessage id="home.description" />
      </p>
      <ButtonWrapper>
        <MessageMe />
      </ButtonWrapper>
    </Presentation>
  )
}

export default ContactCard

const ButtonWrapper = styled.div({
  display: `flex`,
  justifyContent: `center`,
  marginTop: `2rem`,
})
