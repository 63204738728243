import React from "react"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { mqTablet, navBarMobileHeight } from "static/constants"
import ContactCard from "components/contactcard"
import About from "components/about"
import Passions from "components/passions"
import Outro from "components/outro"
import { CONTENT_BG } from "static/global-css"

const MobileLanding = ({ imgData }) => {
  return (
    <div>
      <Wrapper>
        <Background>
          <Img
            style={{ maxHeight: `100%`, height: "100%" }}
            imgStyle={{ objectFit: "cover" }}
            fluid={imgData.image.childImageSharp.fluid}
          />
        </Background>
        <Content>
          <ContactCard />
        </Content>
        <Content>
          <About />
        </Content>
      </Wrapper>
      <Wrapper>
        <Background>
          <Img
            style={{ maxHeight: `100%`, height: "100%" }}
            imgStyle={{ objectFit: "cover" }}
            fluid={imgData.image2.childImageSharp.fluid}
          />
        </Background>
        <Content>
          <Passions />
        </Content>
        <Content>
          <Outro />
        </Content>
      </Wrapper>
    </div>
  )
}

export default MobileLanding

const Content = styled.div({
  display: `none`, // stupid hack https://github.com/gatsbyjs/gatsby/issues/11023
  backgroundColor: `${CONTENT_BG}`,
  padding: 5,
  flexDirection: `column`,
  minHeight: `calc(100vh - ${navBarMobileHeight}px)`,
  [mqTablet]: {
    display: `flex`,
  },
})

const Wrapper = styled.div({
  position: `relative`,
})

const Background = styled.div({
  position: `absolute`,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
})
