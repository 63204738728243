import React, { useRef } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import { a, config, useSpring } from "react-spring"
import Img from "gatsby-image"
import { mqTablet } from "static/constants"
import { CONTENT_BG } from "static/global-css"
import { useAnimationFrame, useWindowSize } from "helpers/hooks"
import Layout from "components/layout"
import SEO from "components/seo"
import Exp from "components/experiences"
import ContactCard from "components/contactcard"
import About from "components/about"
import Passions from "components/passions"
import Outro from "components/outro"
import MobileLanding from "components/mobile-landing"

const IndexPage = () => {
  const { height } = useWindowSize()
  const expRef = useRef(null)

  const data = useStaticQuery(graphql`
    query BackgroundImage {
      image: file(relativePath: { eq: "keyboard.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 5000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image2: file(relativePath: { eq: "laptop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 5000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const springProps = useSpring({
    config: config.gentle,
    from: { transform: `translateY(-15vh)` },
    to: { transform: `translateY(0)` },
  })

  function scrollTerminal(e) {
    const { scrollTop } = e.target
    const element = expRef.current.lastElementChild
    const pct = scrollTop / (height * 2)
    const top = pct * (element.scrollHeight - element.clientHeight)
    element.scrollTo({ top })
  }

  const didScrollParallax = useAnimationFrame(scrollTerminal)

  function handleScroll(e) {
    e.persist()
    didScrollParallax(e)
  }

  function getExcludedTarget() {
    return expRef.current.lastElementChild
  }

  return (
    <Layout getExcludedTarget={getExcludedTarget}>
      <SEO />
      {/* cannot use conditional rendering here: https://github.com/gatsbyjs/gatsby/issues/11023*/}
      <MobileLanding imgData={data} />
      <Parallax onScroll={handleScroll}>
        <ParallaxGroup>
          <ParallaxLayerBack>
            <Img
              style={{ maxHeight: `100%`, height: "100%" }}
              imgStyle={{ objectFit: "cover" }}
              fluid={data.image.childImageSharp.fluid}
            />
          </ParallaxLayerBack>
          <ParallaxLayerFront>
            <CenterWrapper>
              <Content>
                <ContactCard />
              </Content>
            </CenterWrapper>
          </ParallaxLayerFront>
        </ParallaxGroup>
        <ParallaxGroup style={springProps}>
          <ParallaxLayerBack>
            <Img
              style={{ maxHeight: `100%`, height: "100%" }}
              imgStyle={{ objectFit: "cover" }}
              fluid={data.image2.childImageSharp.fluid}
            />
          </ParallaxLayerBack>
          <ParallaxLayerFront>
            <TopBottomWrapper>
              <Content>
                <About />
              </Content>
              <Content>
                <Passions />
              </Content>
            </TopBottomWrapper>
          </ParallaxLayerFront>
        </ParallaxGroup>
        <ParallaxGroup>
          <ParallaxLayerFront>
            <CenterWrapper>
              <Content>
                <Outro />
              </Content>
            </CenterWrapper>
          </ParallaxLayerFront>
        </ParallaxGroup>
      </Parallax>
      <Experiences ref={expRef} />
    </Layout>
  )
}

export default IndexPage

const Experiences = styled(Exp)({
  maxHeight: `75vh`,
  [mqTablet]: {
    display: `none`,
  },
})

const Parallax = styled.div({
  position: `relative`,
  perspective: 1,
  height: `100vh`,
  overflowX: `hidden`,
  overflowY: `auto`,
  perspectiveOrigin: `bottom right`,
  [mqTablet]: {
    // stupid hack https://github.com/gatsbyjs/gatsby/issues/11023
    display: `none`,
  },
})

const ParallaxLayer = styled.div({
  position: `absolute`,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  transformOrigin: `bottom right`,
})

const ParallaxLayerBack = styled(ParallaxLayer)({
  transform: `translateZ(-1px) scale(2)`,
  height: `100vh`,
  zIndex: -1,
})

const ParallaxLayerFront = styled(ParallaxLayer)({
  transform: `translateZ(0)`,
  display: `flex`,
  zIndex: 100,
})

const ParallaxGroup = styled(a.div)({
  position: `relative`,
  height: `100vh`,
  transformStyle: `preserve-3d`,
  // hack to avoid background img's overlapping'
  "&:not(:first-of-type)": {
    [ParallaxLayerBack]: {
      top: `100vh`,
    },
  },
})

const CenterWrapper = styled.div({
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
})

const TopBottomWrapper = styled(CenterWrapper)({
  justifyContent: `space-between`,
})

const Content = styled.div({
  backgroundColor: `${CONTENT_BG}`,
  width: `100vw`,
  height: `33.3vh`,
  minHeight: 350,
  display: `flex`,
  alignItems: `center`,
  padding: `0 32vw 0 2rem`,
  position: `relative`,
})
