import React from "react"
import styled from "@emotion/styled"
import Icon from "assets/icons/sosa/h_Sosa-Regular.svg"
import { FormattedMessage } from "gatsby-plugin-intl"
import Presentation from "./presentation"
import MessageMe from "components/message-me"

const Outro = ({ ...rest }) => (
  <Presentation icon={Icon} {...rest}>
    <h2>
      <FormattedMessage id="outro.title" />
    </h2>
    <h3>
      <FormattedMessage id="outro.available" />
    </h3>
    <p>
      <FormattedMessage id="outro.contactMe" />
    </p>
    <ButtonWrapper>
      <MessageMe />
    </ButtonWrapper>
  </Presentation>
)

export default Outro

const ButtonWrapper = styled.div({
  display: `flex`,
  justifyContent: `center`,
  marginTop: `2rem`,
})
