import React from "react"
import Icon from "assets/icons/sosa/s_Sosa-Regular.svg"
import { FormattedMessage } from "gatsby-plugin-intl"
import Presentation from "./presentation"

const Passions = ({ ...rest }) => (
  <Presentation icon={Icon} {...rest}>
    <h2>
      <FormattedMessage id="passions.title" />
    </h2>
    <h4>
      <FormattedMessage id="passions.code" />
    </h4>
    <p>
      <FormattedMessage id="passions.code.desc" />
    </p>
    <h4>
      <FormattedMessage id="passions.security" />
    </h4>
    <p>
      <FormattedMessage id="passions.security.desc" />
    </p>
    <h4>
      <FormattedMessage id="passions.ethics" />
    </h4>
    <p>
      <FormattedMessage id="passions.ethics.desc" />
    </p>
  </Presentation>
)

export default Passions
