import React from "react"
import { node, func } from "prop-types"
import styled from "@emotion/styled"
import { mqTablet } from "static/constants"
import MessageMe from "components/message-me"
import { HIGHLIGHT_FONT } from "static/global-css"

const Presentation = ({
  children,
  image,
  icon: Icon,
  showMessageMe,
  ...rest
}) => (
  <Container {...rest}>
    <Wrapper>
      <Circle>{image ? image : <Icon />}</Circle>
      {showMessageMe && <MessageMe />}
    </Wrapper>
    <Content>{children}</Content>
  </Container>
)

Presentation.defaultProps = {
  icon: null,
  image: null,
}

Presentation.propTypes = {
  children: node.isRequired,
  image: node,
  icon: func,
}

export default Presentation

const Content = styled.div({
  "> h2": {
    marginBottom: `.5rem`,
  },
  "> h3": {
    marginBottom: `1rem`,
  },
  "> h4": {
    marginTop: `1.0rem`,
    marginBottom: `.5rem`,
  },
  "> p": {
    marginBottom: `.5rem`,
  },
})

const Wrapper = styled.div({
  alignSelf: `center`,
  marginRight: `2rem`,
  "> button": {
    margin: `1rem auto`,
  },
})

const Circle = styled.div({
  width: `calc(12vh + 12vw)`,
  height: `calc(12vh + 12vw)`,
  maxHeight: 200,
  maxWidth: 200,
  borderRadius: `50%`,
  overflow: `hidden`,
  border: `1px solid ${HIGHLIGHT_FONT}`,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  flexShrink: 0,
  "> svg": {
    margin: `auto`,
    height: `80%`,
    path: {
      fill: `${HIGHLIGHT_FONT}`,
    },
  },
  "> .gatsby-image-wrapper": {
    width: `100%`,
  },
})

const Container = styled.section({
  display: `flex`,
  width: `65vw`,
  maxWidth: 1366,
  margin: `auto 0 auto auto`,
  // MediaQuery
  [mqTablet]: {
    margin: `auto`,
    justifyContent: `center`,
    flexWrap: `wrap`,
    width: `90vw`,

    [Wrapper]: {
      marginRight: 0,
    },
    [Circle]: {
      marginBottom: `5vh`,
    },
  },
})
