import React from "react"
import Icon from "assets/icons/sosa/equal_Sosa-Regular.svg"
import { FormattedMessage } from "gatsby-plugin-intl"
import Presentation from "./presentation"

const About = ({ ...rest }) => (
  <Presentation icon={Icon} {...rest}>
    <h2>
      <FormattedMessage id="whatCanIDo.title" />
    </h2>
    <h4>
      <FormattedMessage id="whatCanIDo.what" />
    </h4>
    <p>
      <FormattedMessage id="whatCanIDo.what.desc" />
    </p>
    <h4>
      <FormattedMessage id="whatCanIDo.how" />
    </h4>
    <p>
      <FormattedMessage id="whatCanIDo.how.desc" />
    </p>
    <h4>
      <FormattedMessage id="whatCanIDo.why" />
    </h4>
    <p>
      <FormattedMessage id="whatCanIDo.why.desc" />
    </p>
  </Presentation>
)

export default About
